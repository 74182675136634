
export const timeFormat = {
    methods: {
        printDate(stringDate) {
            if (stringDate) {
                const date = new Date(stringDate)
                if (typeof date.getDate() === 'number' && typeof (date.getMonth() + 1) === 'number' && typeof date.getFullYear() === 'number') {
                    return `${this.pad(date.getDate())}-${this.pad(date.getMonth() + 1)}-${date.getFullYear()}`
                }
            }
            return ''
        },
        pad(num) {
            if (num.toString().length == 1) {
                return `0${num}`
            }
            return num
        }
    }
}

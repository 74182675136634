<template>
  <div class="login-view">
    <div>
      <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="submit">
        <v-expand-transition>
          <v-card
            v-show="expand"
            class="px-5 py-8"
            color="background"
            width="480px"
            style="border-radius: 15px"
          >
            <h2 v-show="expand" class="mb-4">Iniciar sesión</h2>
            <div>
              <!-- <label for="">E-mail</label> -->
              <v-text-field
                outlined
                v-model="email"
                :rules="[rules.required, rules.email]"
                label="E-mail"
              ></v-text-field>
            </div>

            <div>
              <!-- <label for="">Contraseña</label> -->
              <v-text-field
                outlined
                v-model="password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required]"
                :type="show1 ? 'text' : 'password'"
                name="input-10-1"
                @click:append="show1 = !show1"
                label="Contraseña"
              ></v-text-field>
            </div>
            <v-alert v-if="loginError == true" dense type="error">
              Email o contraseña incorrecto.
            </v-alert>
            <v-btn block color="primary" :disabled="disableBtn" type="submit">
              Entrar
            </v-btn>
          </v-card>
        </v-expand-transition>
        <v-expand-transition>
          <v-card
            v-show="expand2"
            class="px-5 py-8"
            color="background"
            width="480px"
            style="border-radius: 15px"
          >
            <h2 class="mb-4">Selecciona un estudio</h2>

            <div>
              <!-- <label for="">Contraseña</label> -->
              <v-select
                :items="items"
                label="Selecciona"
                item-text="name"
                item-value="number"
                v-model="select"
              ></v-select>
            </div>
            <v-alert v-if="loginError == true" dense type="error">
              {{ errorMsg }}
            </v-alert>
            <v-btn
              block
              color="primary"
              :disabled="select == null"
              @click="submitStudio"
            >
              Entrar
            </v-btn>
          </v-card>
        </v-expand-transition>
        <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-form>
    </div>
    <v-overlay :value="overlay2">
      <v-card flat class="mx-auto px-10 my-auto d-flex flex-column justify-center" max-width="470" height="150px">
        <v-card-title> {{ dialogMsg }}</v-card-title>
        <v-progress-linear
          color="admiraGreen"
          indeterminate
          rounded
          height="7"
        ></v-progress-linear>
      </v-card>
    </v-overlay>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      valid: false,
      email: "",
      password: "",
      show1: false,
      rules: {
        required: (value) => !!value || "Campo requerido.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Ingrese un e-mail valido.";
        },
      },
      loginError: false,
      expand: false,
      expand2: false,
      select: null,
      items: [
        { text: "Estudio 1", studio: 1 },
        { text: "Estudio 2", studio: 2 },
        { text: "Estudio 3", studio: 3 },
      ],
      overlay: false,
      errorMsg: "",
      overlay2: false,
      dialogMsg: "Esperando confirmación del técnico",
    };
  },
  computed: {
    ...mapState(["accessRemote", "currentUser"]),
    disableBtn() {
      return this.email.length == 0 || this.password.length == 0;
    },
  },
  methods: {
    ...mapMutations([
      "setStudio",
      "setTypeSlave",
      "setBrowserStudio",
      "setLogin",
    ]),
    ...mapActions(["loginBrowser", "getStudios"]),
    async setTypeSlaveStore(type) {
      return this.setTypeSlave(type);
    },

    async getSudiosStore() {
      return await this.getStudios();
    },
    setEstudioStore(studio) {
      this.setStudio(studio);
    },
    async submit() {
      this.overlay = true;
      this.loginError = false;
      const response = await this.loginBrowser({
        email: this.email,
        password: this.password,
      });
      if (response.status == 401) {
        this.overlay = false;
        this.loginError = true;
        this.errorMsg = "Email o contraseña invalido";
      } else if (response.status == 400) {
        this.overlay = false;
        this.loginError = true;
        this.errorMsg = "Ha ocurrido un error";
      } else {
        this.setLogin(true);
        const studios = await this.getStudios();
        this.fillStudios(studios.data);
        this.overlay = false;
        this.expand = false;
        setTimeout(() => {
          this.expand2 = true;
        }, 1000);
      }
    },
    async submitStudio() {
      await this.setBrowserStudio(this.select);
      this.$globalData.socket.connect();
      await this.setTypeSlaveStore("plus_remote");
      this.overlay2 = true;
    },
    fillStudios(studios) {
      this.items = [];
      studios.forEach((studio) => {
        if (studio.active == true) {
          const studioNumber = studio.name.charAt(studio.name.length - 1);
          studio.number = Number(studioNumber);
          this.items.push(studio);
        }
      });
    },
  },
  watch: {
    accessRemote() {
      if (this.accessRemote == true) {
        this.dialogMsg = "Acceso al Estudio: Entrando..."
        setTimeout(() => {
          this.$router.push("/slave")
        }, 2000);
      }
      if(this.accessRemote == false) {
        this.dialogMsg = "Acceso al Denegando: Saliendo..."
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.expand = true;
    }, 500);
    this.$store.state.role = "slave";
  },
};
</script>

<style scoped>
.login-view {
  height: calc(100vh - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
<template>
  <v-sheet
    height="100%"
    class="overflow-scroll menu"
    style="position: relative; margin-top: 0px; border-radius: 0"
  >
    <v-app
      v-if="
        !allowedCitations ||
        this.actualSpot == null ||
        falseSpot == true ||
        showLoader == true
      "
    >
      <div class="d-flex justify-center align-center" style="height: 100%">
        <v-card flat class="mx-auto px-10" max-width="470" height="300px">
          <v-card-title> Por favor espere </v-card-title>
          <v-card-subtitle>No hay ninguna grabación activada</v-card-subtitle>
          <v-chip
            :color="users.technician != null ? '#749948' : 'disabled'"
            class="py-4 px-5 mb-4"
            text-color="white"
            style="font-size: 14px; border-radius: 22px"
          >
            <v-icon small left>
              {{
                users.technician != null
                  ? "mdi-eye-outline "
                  : "mdi-eye-off-outline"
              }}
            </v-icon>
            Técnico
          </v-chip>
          <v-progress-linear
            color="admiraGreen"
            indeterminate
            rounded
            height="7"
          ></v-progress-linear>
        </v-card>
      </div>
    </v-app>
    <v-row v-else align="center" class="ma-0 pa-5 background">
      <v-row align="center">
        <v-col cols="1" style="max-width: 85px">
          <v-avatar color="admiraGreen" size="60">
            <v-icon style="font-size: 31px" dark
              >mdi-record-circle-outline</v-icon
            >
          </v-avatar>
        </v-col>

        <v-col cols style="min-width: 400px" class="ml-auto">
          <v-row>
            <p class="text--text" style="font-size: 14px; margin: 0px">
              Grabación
            </p>
          </v-row>
          <v-row class="mt-4">
            <h4
              class="text-truncate pr-2 text--text"
              style="
                font-size: 26px;
                font-weight: 300;
                text-align: left;
                line-height: 32px;
              "
            >
              {{ this.actualSpot.spotCode }} - {{ this.actualSpot.name }}
            </h4>
          </v-row>
        </v-col>

        <Connections />

        <v-col cols style="max-width: max-content" class="ml-auto mr-6">
          <v-row class="">
            <p style="font-size: 14px; margin: 0px" class="text--text">
              Estudio
            </p>
          </v-row>
          <v-row class="mt-4">
            <v-chip
              class="py-4 px-5"
              color="#926CB1"
              text-color="white"
              style="font-size: 14px; border-radius: 22px"
            >
              ESTUDIO {{ studio }}
            </v-chip>
          </v-row>
        </v-col>
      </v-row>
      <!-- </v-col> -->
    </v-row>
    <v-divider style="border-width: 1px" class="background" />
    <v-row class="fill-height ma-0" align="center" :class="classObjectHeight">
      <v-row cols="12" style="height: 100%">
        <v-col cols="12" style="padding-top: 22px">
          <v-row class="mx-auto" style="max-width: 1200px">
            <v-col style="position: relative">
              <div
                v-show="role === 'slave_plus' || role === 'slave_plus_remote'"
              >
                <vue-editor
                  id="reader-slave-plus"
                  ref="quillEditorPlus"
                  :value="textSpotSpeaker"
                  :editorToolbar="customToolbar"
                  class="sliderSize"
                  @text-change="changingTextSpotSlave"
                />
                <v-btn
                  class="justify-center admiraGreen mt-4"
                  rounded
                  depressed
                  small
                  dark
                  @click="saveText"
                  >Guardar Texto</v-btn
                >
              </div>
              <v-overlay v-if="roleChangingTxt === 'technician'" absolute>
                <v-row
                  class="fill-height"
                  align-content="center"
                  justify="center"
                >
                  <v-col class="text-subtitle-1 text-center" cols="12">
                    El Técnico está escribiendo
                  </v-col>
                  <v-col cols="6">
                    <v-progress-linear
                      color="admiraGreen"
                      indeterminate
                      buffer-value="0"
                      stream
                      reverse
                      height="6"
                    ></v-progress-linear>
                  </v-col>
                </v-row>
              </v-overlay>
              <template v-if="role === 'slave'">
                <vue-editor
                  id="reader-slave"
                  class="sliderSize"
                  :style="cssVars"
                  :value="textSpotSpeaker"
                  :editorOptions="editorOptions"
                  disabled
                />
              </template>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-row>
    <v-overlay color="background" opacity="1" absolute :value="loadingAudio">
      <h3 class="text--primary">Por favor espere</h3>
      <p class="text--primary">Cargando audio</p>
      <v-chip
        :color="users.technician != null ? '#749948' : 'disabled'"
        class="py-4 px-5 mb-4"
        text-color="white"
        style="font-size: 14px; border-radius: 22px"
      >
        <v-icon small left>
          {{
            users.technician != null ? "mdi-eye-outline " : "mdi-eye-off-outline"
          }}
        </v-icon>
        Técnico
      </v-chip>
      <v-progress-linear
        color="admiraGreen"
        indeterminate
        rounded
        height="7"
      ></v-progress-linear>
    </v-overlay>
    <v-overlay :value="overlay">
      <v-card
        flat
        class="mx-auto px-10 my-auto d-flex flex-column justify-center"
        max-width="470"
        height="150px"
      >
        <v-card-title> {{ dialogMsg }}</v-card-title>
        <v-progress-linear
          color="admiraGreen"
          indeterminate
          rounded
          height="7"
        ></v-progress-linear>
      </v-card>
    </v-overlay>
  </v-sheet>
</template>
<script>
import { defineAsyncComponent } from "vue";
import { mapState, mapActions } from "vuex";
export default {
  components: {
    Connections: defineAsyncComponent(() =>
      import("../components/Connections.vue")
    ),
  },
  data() {
    return {
      customToolbar: [["link", "underline", "italic", "bold"]],
      editorOptions: { modules: { toolbar: false } },
      loadingAudio: false,
      showLoader: true,
      overlay: false,
      dialogMsg: "",
    };
  },
  computed: {
    ...mapState([
      "actualSpot",
      "connectedToTechnician",
      "role",
      "textSpotSpeaker",
      "roleChangingTxt",
      "studio",
      "allowedCitations",
      "sliderSocket",
      "dialogSocketConnection",
      "accessRemote",
      "users"
    ]),
    classObjectHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "height-scroll-xs-sm";
        case "sm":
          return "height-scroll-xs-sm";
        case "md":
          return "height-scroll";
        case "lg":
          return "height-scroll";
        case "xl":
          return "height-scroll";
      }
    },
    cssVars() {
      return {
        "--slider": `${this.sliderSocket}px`,
      };
    },
    falseSpot() {
      return (
        this.actualSpot.mediaType === "MEGAFONIA" &&
        this.actualSpot.texts.criteria?.id === undefined
      );
    },
  },
  methods: {
    ...mapActions(["sendTextSpot"]),
    changingTextSpotSlave() {
      if (this.roleChangingTxt !== "technician" && this.role !== "technician") {
        if (this.firstEntryEditorC) {
          this.$nextTick(() => {
            if (this.$refs.quillEditorPlus.quill.root.innerHTML) {
              this.debounceTextSpot(
                this.$refs.quillEditorPlus.quill.root.innerHTML
              );
            }
          });
        } else {
          this.firstEntryEditorC = true;
          this.showLoader = false;
        }
      }
    },
    async saveText() {
      this.dataSaveSpot = {
        code: this.actualSpot.realSpotCode,
        criLang: this.actualSpot.texts.criteria.id,
      };
      const data = {
        ...this.dataSaveSpot,
        text: this.$refs.quillEditorPlus.quill.root.innerHTML,
      };
      const response = await this.sendTextSpot(data);
      if (response.status == 200) {
        this.$globalData.socket.emit("updateSpotList", {
          toNamespace: "ECI_ESTUDIO",
          toRoom: `estudio${this.studio}`,
          data: true,
        });
      }
    },
    debounceTextSpot(text) {
      this.$globalData.socket.emit("textChangedBy", {
        toNamespace: "ECI_ESTUDIO",
        toRoom: `estudio${this.studio}`,
        data: this.role,
      });
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.$globalData.socket.emit("textRecording", {
          toNamespace: "ECI_ESTUDIO",
          toRoom: `estudio${this.studio}`,
          data: text,
        });
        this.$globalData.socket.emit("textChangedBy", {
          toNamespace: "ECI_ESTUDIO",
          toRoom: `estudio${this.studio}`,
          data: "",
        });
      }, 650);
    },
  },
  watch: {
    roleChangingTxt(newValue, oldValue) {
      if (
        newValue !== oldValue &&
        this.roleChangingTxt &&
        this.roleChangingTxt !== this.role
      ) {
        if (this.role === "slave_plus" || this.role === "slave_plus_remote")
          this.$refs.quillEditorPlus.quill.root.blur();
        else if (this.role === "technician")
          this.$refs.quillEditor.quill.root.blur();
      }
    },
    allowedCitations() {
      if (this.allowedCitations == false) {
        if (this.role === "slave_plus")
          this.$refs.quillEditorPlus.quill.root.blur();
        else if (this.role === "technician")
          this.$refs.quillEditor.quill.root.blur();
      }
    },
    actualSpot() {
      this.$refs.quillEditorPlus.quill.root.blur();
      clearTimeout(this.changingSpot);
      this.showLoader = true;
      this.changingSpot = setTimeout(() => {
        this.showLoader = false;
      }, 650);
    },
    accessRemote() {
      if (this.accessRemote == false) {
        this.dialogMsg = "Sesión finalizada. Saliendo...";
        this.overlay = true;
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    },
  },
  mounted() {
    if (process.env.VUE_APP_MODE == "web") {
      if (this.$store.state.logued == false) {
        this.$router.push("/login");
      }
    }
  },
};
</script>
<style>
#reader-slave.ql-container.ql-snow {
  border: 0 !important;
  border-radius: 25px;
  background: white;
  height: calc(100vh - 215px);
}
#reader-slave-plus.ql-container.ql-snow {
  border: 0 !important;
  background: white;
  height: calc(100vh - 300px);
}
#reader-slave-plus .ql-editor {
  font-size: inherit;
}
#reader-slave .ql-editor {
  font-size: inherit;
}
.floating-btn {
  z-index: 999;
  position: absolute;
  top: 20px;
  right: 20px;
}
.sliderSize .ql-container {
  font-size: var(--slider)
}
</style>

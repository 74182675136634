const role = process.env.VUE_APP_ROLE;

export default function createWebSocketPlugin (socket) {
  return store => {
    store.$socket = socket
    //ejemplo
    //socket.on('connect', store.dispatch('receiveCreatives', [{name:"juan", idCreative:1},{name:"Paqui", idCreative:2}]))
    socket.on('connect', () => {
      console.log("connected")
      socket.emit('joinRoom', { room: `estudio${store.state.studio}`, user: {role: store.state.role, name: store.state.currentUser} });
      store.dispatch('socketConnect', true)
      if(store.state.role == 'slave_plus_remote') store.dispatch('getStudios')
    }) //para mostrar dialog cuando se vuelve a conectar con técnico (master) y enviar role

    socket.on('disconnect',() => store.dispatch('socketConnect', false)) //para mostrar dialog cuando se pierde conexión con técnico (master)

    socket.on('setConnetTechnician', value => {
      // console.log("connectTechnician", value)
      // store.dispatch('connectTechnician')
      if(store.state.role == 'slave_plus_remote') store.dispatch('getStudios')
    })

    socket.on('setUsersConnected', value => {
      store.dispatch('usersConnected', value)
      if(store.state.role == 'slave_plus_remote') store.dispatch('getStudios')
    })

    socket.on('setAllowCitation', value => {
      console.log("accesCitations", value)
      store.dispatch('accesCitations', value)
      if(store.state.role == 'slave_plus_remote') store.dispatch('getStudios')
    })

    socket.on('textRecording', value => {
      console.log("getTextToSpeaker")
      store.dispatch('getTextToSpeaker', value)
      if(store.state.role == 'slave_plus_remote') store.dispatch('getStudios')
    })

    socket.on('langIdSpot', value => {
      console.log("getLangIdSpot")
      store.dispatch('getLangIdSpot', value)
      if(store.state.role == 'slave_plus_remote') store.dispatch('getStudios')
    })

    socket.on('saveDataSpot', value => {
      console.log("getSaveDataSpot")
      store.dispatch('getSaveDataSpot', value)
      if(store.state.role == 'slave_plus_remote') store.dispatch('getStudios')
    })

    socket.on('textChangedBy', value => {
      console.log("getTextChangedBy")
      store.dispatch('getTextChangedBy', value)
      if(store.state.role == 'slave_plus_remote') store.dispatch('getStudios')
    })

    socket.on('sliderFont', value => {
      console.log("getSliderFont")
      store.dispatch('getSliderFont', value)
      if(store.state.role == 'slave_plus_remote') store.dispatch('getStudios')
    })

    socket.on('statusShowingRecorder', value => {
      console.log("getStatusShowingRecorder")
      store.dispatch('getStatusShowingRecorder', value)
      if(store.state.role == 'slave_plus_remote') store.dispatch('getStudios')
    })

    socket.on('updateSpotList', value => {
      console.log("updateSpotList", value)
      store.commit("setUpdateSpotList", value)
      if(store.state.role == 'slave_plus_remote') store.dispatch('getStudios')
    })

    socket.on('pendingCreativeAccessRequest', value => {
      store.commit('setSlaveRemote', value)
    })

    socket.on('joinApproved', value => {
      if(store.state.role == 'slave_plus_remote') {
        store.commit('responseAccessRemote', true)
        store.dispatch('getStudios')
      } 
    })
    socket.on('joinDenied', value => {
      if(store.state.role == 'slave_plus_remote') {
        store.commit('responseAccessRemote', false)
        store.dispatch('getStudios')
      } 
    })

    socket.on('kicked', value => {
      if(store.state.role == 'slave_plus_remote') {
        store.commit('responseAccessRemote', false)
        store.dispatch('getStudios')
      } 
    })

    socket.on('connect_error', (error) => {
      console.error('Error de conexión:', error);
      store.commit('setLostConnection')
    });



  }
}

<template>
  <v-sheet height="100%" class="overflow-scroll" style="position: relative; margin-top:0px; border-radius:0; ">
    <v-row align="center" class="ma-0 pa-5 background">
      <v-row align="center">
        <div style="padding-left:0px;padding-right:0px">
          <v-btn @click="goBack()" icon plain>
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </div>
        <v-col cols="1" style="max-width: 85px;">
          <v-avatar color="admiraGreen" size="60">
            <v-icon style="font-size:31px;" dark>mdi-microphone-outline</v-icon>
          </v-avatar>
        </v-col>

        <v-col cols style="min-width:400px;" class="ml-auto">
          <v-row>
            <p style=" font-size: 14px;margin:0px" class="text--text">Convocatorias</p>
          </v-row>
          <v-row class="mt-4">
            <h4 style="font-size:26px; color:#777; font-weight:300; text-align:left;line-height: 32px;"
              class="text--text">{{ computedDate }} - {{ getTime(currentCitation.initDate) }} -
              {{ getTime(currentCitation.endDate) }}</h4>
          </v-row>
        </v-col>
        <Connections />
        <v-col cols style="max-width:max-content;" class="ml-auto mr-8">
          <v-row style="">
            <p class="text--text" style="font-size: 14px;margin:0px">Total de grabaciones</p>
          </v-row>
          <v-row class="mt-4">
            <h4 style="font-size:25px; font-weight:300; text-align:left;line-height: 32px;" class="text--text">{{
              this.arrSpots.length }}</h4>
          </v-row>
        </v-col>
        <v-col cols style="max-width:max-content;" class="ml-auto mr-6">
          <v-row class="">
            <p style="font-size: 14px;margin:0px" class="text--text">Estudio</p>
          </v-row>
          <v-row class="mt-4">
            <v-chip class="py-4 px-5" color="#926CB1" text-color="white" style="font-size: 14px;border-radius:22px;">
              ESTUDIO {{ studio }}
            </v-chip>
          </v-row>
        </v-col>
      </v-row>
    </v-row>

    <v-divider style="border-width:1px;" />
    <v-row class="ma-0 ml-7 px-5 pt-3" align="center" :class="classObjectHeight">
      <v-col>
        <h4 class="text--text" style="font-size:22px; font-weight:300; text-align:left; line-height: 100%;">Grabaciones
        </h4>
      </v-col>
    </v-row>
    <v-divider />
    <div class="pt-2 overflow-auto mx-1">
      <v-col style="height: calc(100vh - 258px);">
        <div v-if="skeleton" class="px-5">
          <v-skeleton-loader class="mx-auto" max-width type="table-thead"></v-skeleton-loader>

          <v-skeleton-loader class="mx-auto" max-width type="table-row-divider@6" height="455"></v-skeleton-loader>
        </div>
        <v-data-table v-else @click:row="showRecording" style="color: #777; padding-left:20px; padding-right:20px;"
          v-model="selected" item-key="_id" :headers="headers" :items="filteredData" :search="search"
          hide-default-footer :items-per-page="100" class="text--text" show-expand :expanded.sync="expanded"
          :single-expand="true" fixed-header>
          <template v-for="(header, i) in headers" v-slot:[`header.${header.value}`]="{ }">

            <div @click.stop :key="i">
              <v-text-field
                v-if="header.value != 'recordingStatus' && header.value != 'mediaType' && header.value != 'data-table-expand'"
                :key="i" style="font-weight:400" v-model="multiSearch[header.value]" type="text" color="admiraGreen"
                :label="header.text" clearable></v-text-field>
              <v-select v-if="header.value == 'recordingStatus'" label="Estado" style="font-weight:400"
                v-model="multiSearch[header.value]" :items="status" :item-text="item => item.name"
                :item-value="item => item.type" color="admiraGreen" clearable>
              </v-select>
              <v-select v-if="header.value == 'mediaType'" label="Estado" style="font-weight:400;opacity: 0;"
                v-model="multiSearch[header.value]" :items="status" :item-text="item => item.name"
                :item-value="item => item.type" color="admiraGreen" clearable @click:clear="this.multiSearch = {}">
              </v-select>
              {{ header.text }}
            </div>
          </template>
          <template v-slot:group.header="{ items, isOpen, toggle }">
            <td :colspan="headers.length" class="text-start">
              <v-btn icon @click="toggle">
                <v-icon>{{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
              <span style="cursor:pointer;" @click="toggle"> GRUPO {{ items[0].index }}</span>
            </td>
          </template>
          <template v-slot:item.mediaType="{ item }">
            <v-chip small outlined color="grey" class="my-2 px-2 bg-white" style="height:25px;">
              <span class="gray-chips">
                {{ `${item.texts.criteria?.language ? `${item.mediaType} ${item.texts.criteria.language}` : item.mediaType
                  }` }}
              </span>
            </v-chip>
          </template>
          <template v-slot:item.recordingStatus="{ item }">
            <v-chip :color="getStatusName(item.recordingStatus).color" outlined small class="align-center justify-center"
              style="min-width:100px">{{ getStatusName(item.recordingStatus).title }}</v-chip>
          </template>
          <template v-slot:item.texts="{ item }">
            <div class="text-truncate html-text" style="max-width: 500px; width: 100%;">
              <!-- <span v-if="expanded.includes(item)" class="text--text"
                v-html="item.texts.text ? item.texts.text : ''"></span> -->
                <vue-editor v-if="expanded.includes(item)" style="max-width:500px; width: 100%;" class="text--text" ref="quillEditor" :value="item.texts.text"
                  :disabled="true"/>
              <template v-else>{{ htmlToText((item.texts.text || '').toString())}}</template>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </div>
  </v-sheet>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import Connections from "@/components/Connections.vue"
import { VueEditor, Quill } from "vue2-editor";

export default {
  name: 'citationDetails',
  components: {
    Connections
  },
  data: () => ({
    multiSearch: {},
    selected: [],
    search: '',
    skeleton: true,
    status: [
      { name: "Sin audio", type: "empty" },
      { name: "Editado pendiente codificar", type: "edited pending encoding" },
      { name: "Pendiente codificar", type: "pending encoding" },
      { name: "Codificado", type: "encoded" },
      { name: "Codificado en otra sesion", type: "encoded another session" }
    ],
    headers: [
      { text: 'Código', value: 'spotCode', class: 'text--text', width: 100, sortable: false },
      { text: 'Medio', value: 'mediaType', class: 'text--text', width: 100, sortable: false },
      { text: 'Nombre', value: 'name', class: 'width-text-name text--text', width: 200, sortable: false },
      { text: 'Estado', value: 'recordingStatus', class: 'text--text', width: 200, sortable: false },
      { text: 'Texto', value: 'texts', class: 'width-text text--text', sortable: false },
      { text: '', value: 'data-table-expand', cellClass: "expand-btn" },
    ],
    expanded: [],
    codes: [],
    spots: [],
    dateOptions: { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' },
    activities: [],
  }),

  computed: {
    ...mapState(["path", "actualSpot", "studio", "role", "arrSpeakersGroup", "recordings", "arrSpots", "activityId", "activityName", "mediaId", "actualActivity", "allCitations", "auxDateCitations", "currentCitation"]),
    filteredData() {
      if (this.multiSearch) {
        return this.spots.filter((item) => {
          return Object.entries(this.multiSearch).every(([key, value]) => {
            if (value == null) {
              return true;
            }

            function htmlToText(html) {
              const parser = new DOMParser();
              const doc = parser.parseFromString(html, 'text/html');
              return doc.body.textContent || '';
            }

            if (key === 'texts') {
              return htmlToText((item.texts.text || '').toString()).toUpperCase().includes(value.toString().toUpperCase());
            } else if (value.includes("|") && !value.includes("!")) {
              let el = value.split("|");
              return el.some((elem) =>
                (item[key] || "").toString().toUpperCase().startsWith(elem.toString().toUpperCase())
              );
            }
            if (value.substring(0, 1) === "!" && !value.includes("|")) {
              let el = value.split("!");
              return el.some((elem) =>
                !(item[key] || "").toString().toUpperCase().startsWith(elem.toString().toUpperCase())
              );
            }
            if (value.includes("|") && value.substring(0, 1) === "!") {
              let el = value.split("!")[1].split("|");
              return !el.some((elem) =>
                (item[key] || "").toString().toUpperCase().startsWith(elem.toString().toUpperCase())
              );
            }
            if (value.substring(0, 1) === ">") {
              let el = value.split(">");
              if (item[key] !== " ") {
                return Number(item[key] || "") > el[1];
              }
            }
            if (value.substring(0, 1) === "<") {
              let el = value.split("<");
              if (item[key] !== " ") {
                return Number(item[key] || "") < el[1];
              }
            }
            if (value.substring(0, 1) === "=") {
              let el = value.split("=");
              return (item[key] || "").toString().toUpperCase() === el[1].toString().toUpperCase();
            }
            return (item[key] || "").toString().toUpperCase().includes(value.toString().toUpperCase());
          });
        });
      } else {
        return this.spots;
      }
    },
    classObjectHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 'height-scroll-xs-sm'
        case 'sm':
          return 'height-scroll-xs-sm'
        case 'md':
          return 'height-scroll'
        case 'lg':
          return 'height-scroll'
        case 'xl':
          return 'height-scroll'
      }
    },
    computedDate() {
      return this.capitalize(new Date(this.auxDateCitations).toLocaleDateString('es-ES', this.dateOptions))
    },
  },

  methods: {
    ...mapActions(["getSpotsByCode", "fetchSpotsByCode"]),

    validateCorrectRecord(recording) {
      if (recording.mediaType === "MEGAFONIA" || recording.mediaType === "RADIO") {
        return recording.texts?.criteria?.id
      }

      return true
    },


    showRecording(recording) {
      const spotIndex = this.spots.indexOf(recording)

        this.activities.forEach(activity => {
          activity.recordings.forEach(rec => {
            if (rec.audioCode.includes(recording.spotCode) && recording.languageName == activity.mediaId.language) {
              if(recording.mediaType === "MEGAFONIA" || recording.mediaType === "RADIO") {
                if(recording.mediaType == activity.mediaId.name) {
                  this.$store.commit("setActivity", activity)
                  this.$store.commit("setSpeakerGroup", rec.speakerGroupId)
                }
              }
              else {
                if(recording.mediaType == activity.mediaId.name) {
                  this.$store.commit("setActivity", activity)
                  this.$store.commit("setSpeakerGroup", rec.speakerGroupId)
                }
              }
            }
          })
        })
        this.$store.commit("setListSpots", this.spots)
        this.$store.commit("setListActivities", this.activities)
        this.$store.state.actualSpot = recording;
        this.$store.state.actualSpot.index = spotIndex
        this.$store.dispatch('accesCitations', { acces: true, recording: recording})
        this.$globalData.socket.emit('getAllowCitation', {toNamespace:"ECI_ESTUDIO", toRoom:`estudio${this.studio}`, data: { acces: true, recording: recording }})
        this.$store.state.path = "citationDetails"
        this.$router.push({
          name: "showRecording", params: {sessions: false}
        });


    },
    getStatusName(id) {
      if (!id) return '';

      switch (id) {
        case "empty":
          return { title: 'Sin audio', color: '#fd846d' }
        case "edited pending encoding":
          return { title: 'Editado pendiente codificar', color: '#f6ae2d' }
        case "pending encoding":
          return { title: 'Pendiente codificar', color: '#f6ae2d' }
        case "encoded":
          return { title: 'Codificado', color: '#689840' }
        case "encoded another session":
          return { title: 'Codificado en otra sesion', color: '#689840' }
      }
    },
    goBack() {
      this.$router.push({
        name: "citations",
      });
    },

    async setRecordings() {
      this.activities = []
      this.codes = []
      this.currentCitation.activities.forEach(activity => {
        this.activities.push(activity)
        const media = activity.mediaId
        activity.recordings.forEach(recording => {
          recording.audioCode.forEach(code => {
            this.codes.push({
              mediaId: activity.mediaId._id,
              codes: [code],
              taskId: activity.taskId,
              languageName: media.language,
              mediaName: media.name,
            })
          })
        })
      })
      const response = await this.fetchSpotsByCode(this.codes)

      if (response.status == 200) {
        const currentSpots = response.data.data
        for (let index = 0; index < currentSpots.length; index++) {
                    currentSpots[index].languageName = this.codes[index].languageName
                    
                }
                currentSpots.forEach(spot => {
                  const id = spot.spotCode || null
                  this.codes.map((record) => {
                    const spotMatch = record.codes.some(code => code === id)
                    if (spotMatch) {
                      spot.speakerGroupId = record.speakerGroupId
                      return spotMatch
                    }
                  })
                    if (spot.mediaType == "MEGAFONIA" || spot.mediaType == "RADIO") {
                        if(spot.texts?.id != null) {
                            this.spots.push(spot)
                        }
                        else {
                            spot.texts.criteria = {"id":-1,"name":spot.languageName}
                            this.spots.push(spot)
                        }
                    }
                    else {
                        spot.texts.criteria = {"id":-1,"name":spot.languageName}
                        this.spots.push(spot)
                    }
                })
      }

    },
    capitalize(string) {
      return string[0].toUpperCase() + string.slice(1);
    },
    htmlToText(html) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');
      return doc.body.textContent || '';
    },
    getTime(timestamp) {
      var h = new Date(timestamp).getHours();
      var m = new Date(timestamp).getMinutes();

      h = h < 10 ? "0" + h : h;
      m = m < 10 ? "0" + m : m;

      var output = h + ":" + m;
      return output;
    },
  },
  async created() {
    await this.setRecordings()
    this.skeleton = false
  }
}
</script>
<style scoped lang="scss">
>>>.v-data-table-header .width-text-name {
  max-width: 300px;
  min-width: 200px;
}

>>>.v-data-table-header .width-text-st {
  max-width: 200px;
  min-width: 185px;
}

>>>.v-data-table-header .width-text {
  min-width: 300px;
}

>>>.v-data-table__wrapper table>tbody>tr {
  cursor: pointer;
}

>>>.v-data-table__wrapper {
  height: calc(100vh - 250px);
  overflow: auto;
}


</style>
<style lang="scss">
.expand-btn {

  button {
    height: 100%;
  }

  .v-icon.v-icon::after {
    height: 0;
  }
}
.html-text {
  .quillWrapper .ql-snow.ql-toolbar {
    display: none;
  }
  .ql-container.ql-snow {
    background-color: transparent;
  }
  .ql-editor {
    padding: 0 !important;
    color: rgba(var(--v-text-base));
    min-height: auto;
  }
}
</style>

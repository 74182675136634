import Vue from 'vue'
import VueRouter from 'vue-router'
import citations from '../views/citations.vue'
import citationDetails from '../views/citationDetails.vue'
// import showRecording from '../views/showRecording.vue'
import SlaveView from '../views/SlaveView.vue'
import home from '../views/home.vue'
import sessions from '../views/sessions.vue'
import Login from '../views/LoginView.vue'

import RecordingView from '../views/RecordingView.vue'
import HomeSlave from '../views/homeSlave.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: home
  },
  {
    path: '/citations',
    name: 'citations',
    component: citations
  },
  {
    path: '/citationDetails',
    name: 'citationDetails',
    component: citationDetails
  },
  {
    path: '/showRecording',
    name: 'showRecording',
    component: RecordingView
  },
  {
    path:'/sessions',
    name: 'sessions',
    component: sessions
  },
  {
    path: '/slave',
    name: 'slaveView',
    component: SlaveView
  },
  {
    path: '/homeSlave',
    name: 'homeSlave',
    component: HomeSlave
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },

]

const router = new VueRouter({
  mode: process.env.IS_ELECTRON ? 'hash' : 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const role = process.env.VUE_APP_ROLE;
  if ((to.name !== 'slaveView' &&  to.name !== 'homeSlave') && role === 'slave') next({ name: 'slaveView' })
  else next()
})

export default router

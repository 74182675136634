import { render, staticRenderFns } from "./citations.vue?vue&type=template&id=3cd82da2&scoped=true&"
import script from "./citations.vue?vue&type=script&lang=js&"
export * from "./citations.vue?vue&type=script&lang=js&"
import style0 from "./citations.vue?vue&type=style&index=0&id=3cd82da2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cd82da2",
  null
  
)

export default component.exports
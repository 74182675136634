import Vue from "vue";

export default {
  namespaced: true,
  state: {
    sessionSpots: [],
    totalPagesFiles:0,
    searchPageSessions: {
      page: 1,
      itemsPerPage: 100,
      pageSizes: [100, 500, 1000],
      sortBy: 'code',
      sortDesc: "+",
      code: "",
      name: "",
      language: "",
      corporateMedia: "",
      status: ""
    },
    searchPageMega: {
      page: 1,
      itemsPerPage: 10,
      pageSizes: [10, 50, 100],
      sortBy: 'code',
      sortDesc: "+",
      code: "",
      name: "",
      language: "",
      recordingDate: "",
      duration: "",
      status: ""
    },
    searchPageRadio: {
      page: 1,
      itemsPerPage: 10,
      pageSizes: [10, 50, 100],
      sortBy: 'code',
      sortDesc: "+",
      code: "",
      name: "",
      recordingDate: "",
      duration: "",
      referNum: "",
      status: ""
    },
  },
  mutations: {
    setParamaPagination(state,data){
      if(typeof data.pagination.next === "undefined" && typeof data.pagination.prev === "undefined" ){
        return state.totalPagesFiles = 1;
      }
      if (typeof data.total !== "undefined" ) {
        if(typeof data.pagination.next !== "undefined") {
          state.totalPagesFiles = Math.ceil(data.total/data.pagination.next.limit) > 0 ? Math.ceil(data.total/data.pagination.next.limit) : 1
        }
        else {
          state.totalPagesFiles = Math.ceil(data.total/data.pagination.prev.limit) > 0 ? Math.ceil(data.total/data.pagination.prev.limit) : 1
        }
      }
    },
  },
  actions: {
    async fetchSessionSpots({ commit, rootState }, payload) {
      try {
        let response


        if(payload[2] == undefined || payload[2] == ''){

          response = await Vue.prototype.$axios.get(
          `/api/v1/spots/session/${rootState.idStudio}/?limit=${ids[0]}&page=${ids[1]}&sort=${ids[4]}${ids[3]}` 
          );
        }else{
          let strFind = '';

          if (payload[2].code !== '' && payload[2].code != null) {
            strFind += "&code=" + payload[2].code;
          }
          if (payload[2].name !== '' && payload[2].name != null) {
            strFind += "&name=" + payload[2].name;
          }
          if (payload[2].language !== '' && payload[2].language != null) {
            strFind += "&language=" + payload[2].language;
          }
          if (payload[2].corporateMedia !== '' && payload[2].corporateMedia != null) {
            strFind += "&corporateMediaId=" + payload[2].corporateMedia;
          }
          if (payload[2].status !== '' && payload[2].status !== null) {
            strFind += "&status=" + payload[2].status;
          }

          response = await Vue.prototype.$axios.get(
              `/api/v1/spots/session/${rootState.idStudio}/?limit=${payload[0]}&page=${payload[1]}${strFind}&sort=${payload[4]}${payload[3]}`
          );
        }
        let result = response.data;

        commit("setParamaPagination", result);

        return response;
      } catch (error) {}
    },

    async fetchSessionMega({ commit, rootState }, payload) {
        try {

          let response 

        if(payload[2] == undefined || payload[2] == ''){

          response = await Vue.prototype.$axios.get(
          `/api/v1/spots/session/${rootState.idStudio}/?limit=${ids[0]}&page=${ids[1]}&sort=${ids[4]}${ids[3]}` 
          );
        }else{
          let strFind = '';

          if (payload[2].code !== '' && payload[2].code != null) {
            strFind += "&code=" + payload[2].code;
          }
          if (payload[2].name !== '' && payload[2].name != null) {
            strFind += "&name=" + payload[2].name;
          }
          if (payload[2].language !== '' && payload[2].language != null) {
            strFind += "&language=" + payload[2].language;
          }
          if (payload[2].recordingDate !== '' && payload[2].recordingDate != null) {
            const date = new Date(payload[2].recordingDate).getTime()
            strFind += "&recordingDate=" + date;
          }
          if (payload[2].duration !== '' && payload[2].duration != null) {
            strFind += "&duration=" + payload[2].duration+'.000';
          }
          if (payload[2].status !== '' && payload[2].status !== null) {
            strFind += "&status=" + payload[2].status;
          }

          response = await Vue.prototype.$axios.get(
              `/api/v1/spots/mega/?limit=${payload[0]}&page=${payload[1]}${strFind}&sort=${payload[4]}${payload[3]}`
          );
        }
        let result = response.data;

        commit("setParamaPagination", result);

          return response;
        } catch (error) {}
      },
      async fetchSessionRadio({ commit, rootState }, payload) {
        try {
  
          let response 

        if(payload[2] == undefined || payload[2] == ''){

          response = await Vue.prototype.$axios.get(
          `/api/v1/spots/radio/?limit=${ids[0]}&page=${ids[1]}&sort=${ids[4]}${ids[3]}` 
          );
        }else{
          let strFind = '';

          if (payload[2].code !== '' && payload[2].code != null) {
            strFind += "&code=" + payload[2].code;
          }
          if (payload[2].name !== '' && payload[2].name != null) {
            strFind += "&name=" + payload[2].name;
          }
          if (payload[2].recordingDate !== '' && payload[2].recordingDate != null) {
            const date = new Date(payload[2].recordingDate).getTime()
            strFind += "&recordingDate=" + date;
          }
          if (payload[2].duration !== '' && payload[2].duration != null) {
            strFind += "&duration=" + payload[2].duration+'.000';
          }
          if (payload[2].referNum !== '' && payload[2].referNum != null) {
            strFind += "&criLang=" + payload[2].referNum;
          }
          if (payload[2].status !== '' && payload[2].status !== null) {
            strFind += "&status=" + payload[2].status;
          }

          response = await Vue.prototype.$axios.get(
              `/api/v1/spots/radio/?limit=${payload[0]}&page=${payload[1]}${strFind}&sort=${payload[4]}${payload[3]}`
          );
        }
        let result = response.data;

        commit("setParamaPagination", result);

          return response;
        } catch (error) {}
      },

    async fetchCoporateMedias() {
      try {
        const response = await Vue.prototype.$axios.get(
          `/api/v1/corporateMedias/?limit=100`
        );
        return response;
      } catch (error) {}
    },
    async changeStatusEncoded({rootState}) {
      
      try {
        const response = await Vue.prototype.$axios.post(`/api/v1/spots/session/${rootState.idStudio}/completeEncoded`)
        return response
      } catch (error) {
        
      }
    },
    async changeStatusEncodedSelected({rootState}, payload) {
      
      try {
        const response = await Vue.prototype.$axios.post(`/api/v1/spots/session/${rootState.idStudio}/completeSelected`,
          {ids: payload}
        )
        return response
      } catch (error) {
        
      }
    },
    async updateGhostSpot({}, payload) {
      try {
        const response = await Vue.prototype.$axios.put(`/api/v1/spots/${payload.spotCode}/recording`, JSON.stringify(payload.dataSpot))
        return response.data
      } catch (error) {
        
      }
    }
  },
  getters: {},
};

<template >
  <div>
    <!--Begin nav-->
    <v-app-bar
      app
      color="admiraGreen"
      flat
      dark
      height="60"
      
    >
      
      <!-- <router-link :to="{name: 'home'}"> -->
        <div class="ml-8" >
            <v-img
                alt="Vuetify Logo"
                contain
                src="@/assets/eci-logo.png"
                transition="scale-transition"
                height="45"
                width="100"
                
        />
        </div>
      <v-spacer />
        <v-btn
            v-if="showConfig"
            class="config-btn"
            elevation="0"
            color="admiraGreen"
            style="height: 56px; border-radius: 10px"
            width="90"
            @click="configDialog = true"
        >
            <v-icon large color="white">mdi-cog</v-icon>
            <div
            class="line-btn-redcorder"
            ></div>
        </v-btn>
        <v-btn v-if="showLogout" color="admiraGreen" elevation="0" class="logout-btn" @click="logout">
          <v-icon>
            mdi mdi-logout
          </v-icon>
        </v-btn>
        <v-banner
        color="red"
        elevation="9"
        rounded
        shaped
        single-line
        sticky
        style="padding-right:16px;top: -50px;"
        v-if="port === '5001'"
      >
        <v-icon
          style="width:20px ;height:20px; font-size:20px;margin-right:10px "
        >mdi-alert-circle-outline</v-icon>
        Conectado a producción
      </v-banner>
    </v-app-bar>
    <DialogConfig :dialog="configDialog" :close="() => configDialog = false" :reconnectSocket="reconnectSocket" />
      <v-overlay :value="overlay2">
      <v-card flat class="mx-auto px-10 my-auto d-flex flex-column justify-center" max-width="470" height="150px">
        <v-card-title> {{ dialogMsg }}</v-card-title>
        <v-progress-linear
          color="admiraGreen"
          indeterminate
          rounded
          height="7"
        ></v-progress-linear>
      </v-card>
    </v-overlay>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { mapState, mapGetters } from "vuex";
export default {
  name: "Navbar",
  components: {
    DialogConfig: defineAsyncComponent(() => import("../dialogs/ConfigDialog.vue"))
  },
  data(){
    return{
      configDialog: false,
      dialogMsg: "",
      overlay2: false,
    }
  },
  computed: {
    ...mapState([
      "port", "studio", "currentUser"
    ]),
    ...mapGetters(["showConnections"]),
    ...mapState(["role","connectedToTechnician"]),
    showConfig() {
      return this.role == 'technician'
    },
    showLogout() {
      return process.env.VUE_APP_MODE != "electron" && this.currentUser != null
    }
  },
  methods: {
    reconnectSocket() {
        this.$globalData.socket.disconnect();
        this.$globalData.socket.connect();
    },
    logout() {
      this.overlay2 = true
      this.dialogMsg = "Saliendo..."
        setTimeout(() => {
          window.location.reload();
        }, 2000);
    }
  },
  mounted() {
    if(process.env.VUE_APP_MODE == "electron") {
      if(this.studio == null) {
        this.configDialog = true
      }
    }
    }
};
</script>

<style scoped>

>>> .v-toolbar__content {
  padding-right: 12px !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

header{ 
    left: 0 !important;
}
   


.alignDrawer {
  text-align: left;
  padding: -60px;
}

.logout-btn {
  position: absolute;
  right: 0px;
  top: 12px;
  z-index: 99;
}

</style>
import axios from "axios";
import Vue from 'vue'
import store from '../store'

let config = {
    baseURL: `${process.env.VUE_APP_URL_API}:${process.env.VUE_APP_PORT}`,
}
  
const _axios = axios.create(config)

_axios.interceptors.request.use(async function (config) {  
    let token = await store.dispatch("checkToken", config);
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
})

export default {
    install () {
        Vue.prototype.$axios = _axios
    }
};